import request from '@/utils/request'

export function homeData() {
    return request({
        url: '/api/home',
        method: 'GET'
    })
}

export function navData() {
    return request({
        url: '/api/nav',
        method: 'GET'
    })
}

export function systemById(id) {
    return request({
        url: '/api/system/' + id,
        method: 'GET'
    })
}

export function newsById(id) {
    return request({
        url: '/api/news/' + id,
        method: 'GET'
    })
}

export function newsList(count) {
    return request({
        url: '/api/newsList/' + count,
        method: 'GET'
    })
}

export function productList() {
    return request({
        url: '/api/productList',
        method: 'GET'
    })
}

export function product(id) {
    return request({
        url: '/api/product/' + id,
        method: 'GET'
    })
}
