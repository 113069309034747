<template>
    <Layout cheader-class="header-transparent" :footer-style="1" :show-cta="false">
        <div class="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow">
            <div class="height-636 bg-overlay bg_image" :style="{'background-image': `url(${require(`@/assets/images/bg/banner.png`)})`}" style="height: 412px">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-7 offset-lg-5">
                            <div class="text-left my-banner">
                                <h1>精工生产</h1>
                                <h2>专业领域覆盖</h2>
                                <p style="color: #52565e !important;">致力于粉体设备领域技术领先型企业</p>
                                <p style="color: #52565e !important;">坚持企业可持续的、协调的、全面的、健康的发展，创建行业的知名品牌</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle
                            class="mb--50"
                            text-align="center"
                            title="密闭科技解决方案&nbsp;&nbsp;&nbsp;&nbsp;凝聚企业核心竞争力"
                            description="从设备到系统真正交钥匙的解决方案"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <ServiceOne
                    service-style="service__style--1 bg-color-blackest radius mt--25"
                    icon-size="62"
                    text-align="center"
                    :serviceList="serviceList"
                />
            </div>
        </section>

        <Separator/>

        <section class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle
                            class="mb--50"
                            text-align="center"
                            title="公司在医疗设备行业拥有先进的技术和专业的团队"
                            description="行业资讯"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row mt_dec--30">
                    <div class="col-lg-12">
                        <div class="row row--15">
                            <div class="col-lg-4 col-md-6 col-12 mt--30"
                                 data-aos="slide-up"
                                 data-aos-duration="800"
                                 :data-aos-delay="100 + index"
                                 v-for="(blog, index) in posts"
                                 :key="index">
                                <BlogPost :blog="blog"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 text-center">
                        <div class="rwt-load-more text-center mt--60">
                            <router-link class="btn-default" to="/news">
                                更多行业资讯   <Icon name="more"/>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Separator/>

        <section class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle
                            class="mb--50"
                            text-align="center"
                            title="泷沙正在成为制药企业智能化提升对最好选择"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                    <Counter
                        :counter-style="5"
                        text-align="center"
                        :counter-data="counterFiveData"
                        column="col-lg-3 col-md-6 col-sm-6 col-12"
                    />
                </div>
            </div>
        </section>
        <Separator/>

        <section class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle
                            class="mb--50"
                            text-align="center"
                            title="合作伙伴"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 mt--10">
                        <Brand
                            :brand-list="brandList3"
                            :brand-style="3"
                            data-aos="fade-up"
                            data-aos-delay="60"/>
                    </div>
                </div>
            </div>
        </section>

        <Separator/>

        <section class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle
                            class="mb--50"
                            text-align="center"
                            title="关于我们"
                            description="河北泷沙机械科技有限公司成立于2016年，位于中华北大街与北环城高速交口，其地理位置非常优越，交通便利。公司自成立以来，始终坚持以人为本诚信立业的经营原则，使企业在激烈的市场竞争中终保持竞争力，实现企业快速、稳定地发展。公司经营范国包括食品机械及配件，包装机械及配件，制药设备及配件，非标自动化设备及配件，桁架机器人及配件，智能库设备及配件，铁路运输设备配件的生产、 销售。河北泷沙机械科技有限公司以诚信、实力和产品质量获得业界的高度认可。欢迎各界朋友莅临参观、指导和业务洽谈。"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
            </div>
        </section>

    </Layout>
</template>
<script>
import Layout from "@/components/common/Layout";
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import ServiceOne from '@/components/elements/service/ServiceOne'
import Counter from "@/components/elements/counterUp/Counter"
import Brand from "@/components/elements/brand/Brand"
import BlogPost from '@/components/blog/BlogPost'
import { homeData } from "@/api/home";
import Icon from "@/components/icon/Icon.vue";

export default {
    name: 'Home',
    components: {Icon, Layout, Separator, SectionTitle, ServiceOne, Counter, Brand, BlogPost },
    data() {
        return {
            counterFiveData: [
                {
                    plusSign: true,
                    number: 350,
                    title: '我们的客户',
                },
                {
                    number: 5000,
                    title: '厂房面积',
                },
                {
                    number: 2015,
                    title: '成立时间',
                },
                {
                    plusSign: true,
                    number: 100,
                    title: '员工人数',
                }
            ],
            brandList3: [],
            posts: [
                {
                    id: '1',
                    img: require(`@/assets/images/home/1.png`),
                    publishedAt: '2023-05-23',
                    title: '行业新闻标题行业新闻标题行业新闻标题行业新闻标题',
                    excerpt: 'We have always known the power of digital we\'ve.',
                    body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                    categories: ['Development', 'Company', 'Marketing'],
                    tags: ['Corporate', 'Agency', 'Creative', 'Design']
                },
                {
                    id: '2',
                    img: require(`@/assets/images/home/2.png`),
                    author: 'Fatima Asrafy',
                    authorId: '2',
                    view: '70 views',
                    publishedAt: '2023-05-23',
                    title: '行业新闻标题行业新闻标题行业新闻标题行业新闻标题',
                    excerpt: 'We have always known the power of digital we\'ve.',
                    body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                    categories: ['UX Design', 'Company'],
                    tags: ['Minimal', 'Company', 'Development', 'Design']
                },
                {
                    id: '3',
                    img: require(`@/assets/images/home/3.png`),
                    author: 'John Dou',
                    authorId: '3',
                    view: '70 views',
                    publishedAt: '2023-05-23',
                    title: '行业新闻标题行业新闻标题行业新闻标题行业新闻标题',
                    excerpt: 'We have always known the power of digital we\'ve.',
                    body: `<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                           <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                           <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                           <p>If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                           <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>`,
                    categories: ['Development', 'UX Design', 'Business', 'Company'],
                    tags: ['App Landing', 'Startup', 'Development', 'Design']
                }
            ],
            serviceList: [
                {
                    icon: 'split-branch',
                    color: '#037ef3',
                    title: '负压隔离系统',
                    description: '可满足OEB5等级的生产环境要求'
                },
                {
                    icon: 'weight',
                    color: '#f85a40',
                    title: '吨包投料系统',
                    description: '一点多投、精准定位'
                },
                {
                    icon: 'oil-industry',
                    color: '#00c16e',
                    title: '称重灌装系统',
                    description: '强调精度、效率和灵活性。无菌灌装领域经验丰富的领导者'
                },
                {
                    icon: 'circle-two-line',
                    color: '#7552cc',
                    title: '无菌分装系统',
                    description: '遵照新版GMP 全部自动化'
                },
                {
                    icon: 'measuring-cup',
                    color: '#0cb9c1',
                    title: '密闭转料系统',
                    description: '负压抽吸式原理，杜绝物料堵塞与泄露'
                },
                {
                    icon: 'box',
                    color: '#f48924',
                    title: '计量配比系统',
                    description: '精确计量，多重物料的精确配比混合加料'
                },
            ]
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            homeData().then(res => {
                /*
                    密闭科技解决方案    凝聚企业核心竞争力 位置修改为静态你数据
                    下面注释代码为从后台加载数据
                 */
                // res.data.data.schemeData.forEach((item, index) =>{
                //     this.serviceList[index].title = item.schemeName
                //     this.serviceList[index].description = item.intro
                //     this.serviceList[index].id = item.id
                // })

                this.posts.splice(0,this.posts.length)
                res.data.data.newsData.forEach(item => {
                    this.posts.push({ id: item.id, title: item.title, publishedAt: item.publishTime.substring(0,11), img: process.env.VUE_APP_BASE_API + item.coverUrl })
                })

                res.data.data.partnerData.forEach(item => {
                    this.brandList3.push({ image: process.env.VUE_APP_BASE_API + item.logo })
                })

            })
        }
    }
}
</script>
