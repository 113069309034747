import Vue from 'vue'
import VueRouter from 'vue-router'
import ErrorPage from '../views/404'
import Home from '../views/home/Index'
import ClosedTransfer from '../views/system/Index'
import Product from '../views/product/Index'
import ProductDetail from '../views/product/Detail'
import Introduction from '../views/introduction/Index'
import News from '../views/news/Index'
import NewsDetails from '../views/news/details/Index.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: '河北泷沙机械科技有限公司',
        },
    },
    {
        path: '/ClosedTransfer',
        name: 'ClosedTransfer',
        component: ClosedTransfer,
        meta: {
            title: '河北泷沙机械科技有限公司',
        },
    },
    {
        path: '/Product/:productType',
        name: 'Product',
        component: Product,
        props: true,
        meta: {
            title: '河北泷沙机械科技有限公司',
        },
    },
    {
        path: '/ProductDetail/:id',
        name: 'ProductDetail',
        component: ProductDetail,
        props: true,
        meta: {
            title: '河北泷沙机械科技有限公司',
        },
    },
    {
        path: '/Introduction',
        name: 'Introduction',
        component: Introduction,
        meta: {
            title: '河北泷沙机械科技有限公司',
        },
    },
    {
        path: '/News',
        name: 'News',
        component: News,
        meta: {
            title: '河北泷沙机械科技有限公司',
        },
    },
    {
        path: '/NewsDetails/:id',
        name: 'NewsDetails',
        component: NewsDetails,
        meta: {
            title: '河北泷沙机械科技有限公司',
        },
    },
    {
        path: '/*',
        name: 'Error',
        component: ErrorPage,
        meta: {
            title: '河北泷沙机械科技有限公司',
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
