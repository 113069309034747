import axios from 'axios';

const instance = axios.create({
    baseURL:process.env.VUE_APP_BASE_API,
    timeout: 5000
});

instance.defaults.withCredentials = true
function request(config) {
    return instance(config);
}

export default request;
