<template>
    <!-- Start Copy Right Area  -->
    <div class="copyright-area copyright-style-one" style="background: #00334e!important;">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-8 col-sm-12 col-12">
                    <div class="copyright-left">
                        <ul class="ft-menu link-hover">
                            <li>
<!--                                <router-link to="https://beian.miit.gov.cn/" style="color: white!important;">冀ICP备20017919号-1</router-link>-->
                                <a href="https://beian.miit.gov.cn/" target="_blank" style="color: white!important;">冀ICP备20017919号-1</a>
                            </li>
                            <li>
                                <router-link to="#" style="color: white!important;">使用条款</router-link>
                            </li>
                            <li>
                                <router-link to="/contact" style="color: white!important;">联系我们</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-4 col-sm-12 col-12">
                    <div class="copyright-right text-center text-lg-end">
                        <p class="copyright-text" style="color: white!important;">© 泷沙 {{ new Date().getFullYear() }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Copy Right Area  -->
</template>

<script>
    export default {
        name: 'Copyright'
    }
</script>
