<template>
    <Layout :show-cta="false">
        <!-- Start Error-Area Area  -->
        <div class="error-area ptb--200 ptb_sm--60 ptb_md--80">
            <div class="container">
                <div class="row align-item-center">
                    <div class="col-lg-12">
                        <div class="error-inner">
                            <h1>404</h1>
                            <h2 class="title">迷路了吗？404来帮你！</h2>
                            <p class="description">我们知道有时候你会迷路，但别担心，我们的页面已经准备好带你回家了。<br/>它比你想象中的更聪明，更有耐心，而且不会像你的导航一样让你走弯路。<br/>让我们一起来享受这段旅程吧！</p>
<!--                            <form action="#" class="blog-search">-->
<!--                                <input type="text" placeholder="Search Here...">-->
<!--                                <button class="search-button">-->
<!--                                    <Icon name="search" size="20"/>-->
<!--                                </button>-->
<!--                            </form>-->
                            <div class="view-more-button"><a class="btn-default" href="/">返回首页</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Error-Area Area  -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Icon from '../components/icon/Icon'

    export default {
        name: '404',
        components: {Icon, Layout}
    }
</script>
