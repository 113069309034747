<template>
    <div :class="`rn-accordion-style ${accordionStyleClass} accordion`">
        <div class="accordion" :id="id">
            <div class="accordion-item card d-flex flex-row" v-for="content in accordionContent" style="padding:20px 30px">
<!--                <label theme="filled" size="48" fill="#4a90e2" :strokeWidth="2"/>-->
                <Icon name="label" size="24" fill="#4a90e2"/>
                <span style="font-size: 18px;line-height: 27px;padding-left: 14px">
                    {{ content.description }}
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import Icon from "@/components/icon/Icon.vue";

export default {
    name: 'Accordion',
    components: {Icon},
    props: {
        id: {
            type: String,
            default: 'accordionExample'
        },
        accordionStyleClass: {
            type: String
        },
        accordionContent: {
            type: Array,
            default: function () {
                return [
                    'What is Doob ? How does it work?',
                    'How can I get the customer support?',
                    'Can I get update regularly and For how long do I get updates?',
                    'How can I run doob html template?'
                ]
            }
        }
    }
}
</script>
