<template>
    <Layout cheader-class="header-transparent" :footer-style="1" :show-cta="false">
        <div class="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow">
            <div class="height-636 bg-overlay bg_image" :style="{'background-image': `url(${require(`@/assets/images/product/banner.png`)})`}">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text-center product-banner">
                                <h1>泷沙产品中心的持续迭代</h1>
                                <p>让您的工艺流程更加安全和高效</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Separator/>
        <section class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row row--30">
                    <Tab :tab-data="tabData" :active="productType"/>
                </div>
            </div>
        </section>
    </Layout>
</template>
<script>
import Layout from "@/components/common/Layout";
import Separator from "@/components/elements/separator/Separator";
import Tab from "@/components/elements/tab/Tab";
import { productList } from "@/api/home"

export default {
    name: 'Product',
    components: {Tab, Separator, Layout},
    data() {
        return {
            tabData: [],
            productType: 0
        }
    },
    watch: {
        '$route.params': function (newParams, oldParams) {
            this.productType = newParams.productType
        }
    },
    created() {
        this.init()
        this.productType = typeof this.$route.params.productType === 'string' ? parseInt(this.$route.params.productType) : this.$route.params.productType
    },
    methods: {
        init() {
            productList().then(res => {
                console.log(res.data.data)
                this.tabData = res.data.data
                this.tabData.forEach(item => {
                    item.id = 'a' + item.id
                })
            })
        }
    }
}
</script>
