<template>
<!--    <i v-html="iconSvg(name)" :class="`size-${size} ${iconClass}`"/>-->
    <icon-park :type="name" theme="outline" :size="size" :strokeWidth="strokeWidth" :fill="fill"/>
</template>

<script>
    import { IconPark } from '@icon-park/vue/es/all'
    export default {
        name: 'Icon',
        components: { IconPark },
        props: {
            fill: {
                type: String,
                default: null
            },
            name: {
                type: String,
                required: true
            },
            strokeWidth: {
                type: Number,
                default: 2
            },
            size: {
                type: String|Number,
                default: 16
            },
            iconClass: {
                type: String,
                default: ''
            }
        }
    }
</script>
