<template>
    <div>
        <!-- Start Footer Area  -->
        <footer v-if="data.showFooter" class="rn-footer footer-style-default variation-two"
                style="background: #004d73!important;">
            <div class="footer-top">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-2 col-md-6 col-sm-6 col-6" v-for="item in product">
                            <div class="rn-footer-widget">
                                <h4 class="title" style="color:rgba(255,255,255,0.81)!important;">{{ item.name }}</h4>
                                <div class="inner">
                                    <ul class="footer-link link-hover">
                                        <li v-for="i in item.children">
                                            <router-link :to="{ name: 'ProductDetail', params: { id: i.id }}"
                                                         style="color: rgba(255,255,255,0.81)!important;">
                                                {{ i.productName }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="row"><h4 class="title" style="color:rgba(255,255,255,0.81)!important;font-size: 18px">联系我们</h4></div>
                        <div class="row">
                            <div class="col-lg-1" style="margin-bottom: 14px">
                                <img src="../../../assets/images/QRCode.png"/>
                            </div>
                            <div class="col d-flex flex-column justify-content-center">
                                <div style="color: rgba(255,255,255,0.81)!important;"><a href="tel:0311-67668859"
                                                                                       style="color: rgba(255,255,255,0.81)!important;">联系电话:
                                    0311-67668859</a></div>
                                <div style="color: rgba(255,255,255,0.81)!important;">联系地址:
                                    河北省石家庄市正定县北早现乡下水屯村工业路1号</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
<!--            <Newsletter v-if="data.showNewsletter"/>-->
        </footer>
        <!-- End Footer Area  -->

        <Copyright v-if="data.showCopyright"/>

        <ScrollTop/>
    </div>
</template>

<script>
import Copyright from './Copyright'
import Button from '../../elements/button/Button'
import Icon from '../../icon/Icon'
import ScrollTop from './ScrollTop'
import Logo from '../../elements/logo/Logo'
import Newsletter from './Newsletter'

export default {
    name: 'Footer',
    components: {Newsletter, Logo, ScrollTop, Icon, Button, Copyright},
    props: {
        product: Array,
        data: {
            default: null
        }
    }
}
</script>
