<template>
    <Layout cheader-class="header-transparent" :footer-style="1" :show-cta="false">
        <div class="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow">
            <div class="height-636 bg-overlay bg_image" :style="{'background-image': `url(${require(`@/assets/images/system/banner.png`)})`}" style="height: 412px;">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text-left system-banner">
                                <h1>{{ system.schemeName }}</h1>
                                <p>{{ system.subtitle }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row row--30">
                <div class="col-12">
                    <SectionTitle
                        class="mb--50 mt--50"
                        text-align="center"
                        title="系统介绍"
                        description="System Introduction"
                        data-aos="fade-up"
                        data-aos-delay="60"
                    />
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3 col-xl-3 order-1 order-lg-1">
                    <div class="frame-image" data-aos="fade-up" data-aos-delay="100">
                        <img :src="system.coverUrl" :alt="system.schemeName"/>
                    </div>
                </div>
                <div class="offset-lg-1 col-lg-7 col-xl-7 order-2 order-lg-2 mt_md--40 mt_sm--40 " data-aos="fade-up" data-aos-delay="100">
                    <p>{{ system.introduction }}</p>
                </div>
            </div>
        </div>

        <div class="container" v-if="pictures.length > 0">
            <div class="row mt--75">
                <div class="offset-lg-4 col-lg-4">
                    <div
                        v-for="item in pictures"
                        :key="item.id"
                        class="pb--30">
                        <div class="frame-image"
                             data-aos="fade-up"
                             data-aos-delay="60">
                            <img :src="item.url" style="max-width: 100%;max-height: 100%;"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-12">
                        <SectionTitle
                            class="mb--50"
                            text-align="center"
                            title="产品特色"
                            description="characteristic"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 mt--10">
                        <Characteristic
                            :accordionContent="accordionContent"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
            </div>
        </section>

    </Layout>
</template>

<script>
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Layout from '@/components/common/Layout.vue'
import Separator from '@/components/elements/separator/Separator'
import Characteristic from "@/components/elements/accordion/Characteristic"
import { systemById } from "@/api/home"

export default {
    name: 'ClosedTransfer',
    components: { Layout, Separator, SectionTitle, Characteristic },
    data() {
        return {
            system: {
                schemeName: ''
            },
            accordionContent: [],
            pictures: []
        }
    },
    created() {
        this.init(this.$route.query.id)
    },
    watch: {
        '$route.query.id'(newId) {
            this.init(newId);
        },
    },
    methods: {
        init(id) {
            systemById(id).then(res => {
                this.system = res.data.data.scheme
                this.system.coverUrl = process.env.VUE_APP_BASE_API + this.system.coverUrl
                this.accordionContent = res.data.data.traits
                this.pictures = res.data.data.pictures
                this.pictures.forEach(item => {
                    item.url = process.env.VUE_APP_BASE_API + item.url
                })
            })
        }
    }
}
</script>

