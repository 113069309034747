<template>
    <div class="rbt-separator-mid">
        <div class="container">
            <div class="row">
                <div class="offset-lg-1 col-lg-10 my-separator"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MySeparator'
}
</script>
