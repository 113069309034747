<template>
    <Layout>
        <div class="rn-blog-details-area">
            <div class="post-page-banner rn-section-gapTop">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="content text-center">
                                <div class="page-title">
                                    <h1 class="theme-gradient" v-html="post.title"/>
                                </div>
<!--                                <ul class="rn-meta-list">-->
<!--                                    <li>-->
<!--                                        <Icon name="user" class="size-20"/> <router-link to="#">Irin Pervin</router-link>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <Icon name="calendar" class="size-20"/> {{ post.publishedAt }}-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                                <div class="thumbnail alignwide mt&#45;&#45;60">-->
<!--                                    <img class="w-100 radius"-->
<!--                                         :src="post.img"-->
<!--                                         :alt="post.title">-->
<!--                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog-details-content pt--60 rn-section-gapBottom">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 offset-lg-2">
                            <div class="content">
                                <p v-html="post.body"/>
                                <!-- End Contact Form Area  -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../../../components/common/Layout'
import Breadcrumb from '../../../components/elements/breadcrumb/Breadcrumb'
import Icon from '../../../components/icon/Icon'
import { newsById } from "@/api/home"

export default {
    name: 'NewsDetails',
    components: {Icon, Layout, Breadcrumb},

    data() {
        return {
            post: {
                title: '',
                body: ''
            }
        }
    },
    created() {
        this.init(this.$route.params.id)
    },
    methods: {
        init(id) {
            newsById(id).then(res => {
                this.post.title = res.data.data.title
                this.post.body = res.data.data.content
            })
        }
    }
}
</script>
