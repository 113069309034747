<template>
    <Layout cheader-class="header-transparent" :footer-style="1" :show-cta="false">
<!--        <div class="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow">-->
<!--            <div class="height-636 bg-overlay bg_image" :style="{'background-image': `url(${require(`@/assets/images/product/banner.png`)})`}">-->
<!--                <div class="container">-->
<!--                    <div class="row">-->
<!--                        <div class="col-lg-12">-->
<!--                            <div class="text-center product-banner">-->
<!--                                <h1>泷沙产品中心的持续迭代</h1>-->
<!--                                <p>让您的工艺流程更加安全和高效</p>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <Separator/>-->
        <div class="rwt-video-popup-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            :title="product.productName"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <Video v-if="videoData.length > 0" :video-data="videoData" :description="product.introduction" column="col-lg-6 col-md-6 col-sm-6 col-12 mt--100"/>
                <div v-else class="row pt--60">
                    <div class="col-lg-3 frame-image"
                         data-aos="fade-up"
                         data-aos-delay="60">
                        <img :src="product.coverUrl" style="width: 100%;height: auto" />
                    </div>
                    <div class="offset-lg-1 col-lg-7 pt-5">
                        <div class="container"
                             data-aos="fade-up"
                             data-aos-delay="60">
                            <p>{{product.introduction}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Separator/>
        <div>
            <div class="container">
                <div class="row pt--70">
                    <div class="offset-lg-4 col-lg-4 pb--70">
                        <div
                            v-for="item in pictures"
                            :key="item.id"
                            class="pb--30">
                            <div class="frame-image"
                                 data-aos="fade-up"
                                 data-aos-delay="60">
                                <img :src="item.url" style="max-width: 100%;max-height: 100%;"/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <section class="rn-service-area " v-if="tableTitles.length > 0 && textParams.length < 1">
            <div class="container">
                <div class="row"
                     data-aos="fade-up"
                     data-aos-delay="60">
                    <div class="col-lg-12 text-center pb--70">
                        <h2 class="title w-600" style="color: #65676b;">产品参数</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="offset-lg-1 col-lg-10">
                        <div class="table-container">
                            <table class="my-table"
                                   data-aos="fade-up"
                                   data-aos-delay="60">
                                <tr>
                                    <th v-for="item in tableTitles">{{ item.label }}</th>
                                </tr>
                                <tr v-for="item in tableData">
                                    <td v-for="value in item">{{ value }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <my-separator v-if="textParams.length > 0"/>
        <!--文本参数-->
        <div v-if="textParams.length > 0">
            <div class="container">
                <div class="row ptb--50"
                     data-aos="fade-up"
                     data-aos-delay="60" >
                    <div class="offset-lg-1 col-lg-3 ptb-my--60" >
                        <span style="font-size: 25px">产品参数</span>
                    </div>
                    <div class="col-lg-7 ptb-my--60" >
                        <div v-for="item in textParams">
                            <span style="font-size: 15px">{{ item.label }}： {{ item.value }}</span>
                        </div>
                    </div>
                </div>
                <div v-if="tableTitles.length > 0" class="row ptb--50">
                    <div class="offset-lg-1 col-lg-10">
                        <div class="table-container">
                            <table class="my-table"
                                   data-aos="fade-up"
                                   data-aos-delay="60">
                                <tr>
                                    <th v-for="item in tableTitles">{{ item.label }}</th>
                                </tr>
                                <tr v-for="item in tableData">
                                    <td v-for="value in item">{{ value }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <my-separator v-if="traits.length > 0"/>
        <!--特色-->
        <div v-if="traits.length > 0">
            <div class="container">
                <div class="row mt--50 mb--50"
                     data-aos="fade-up"
                     data-aos-delay="60">
                    <div class="offset-lg-1 col-lg-3 ptb-my--60" >
                        <span style="font-size: 25px">产品特色</span>
                    </div>
                    <div class="col-lg-7 ptb-my--60" >
                        <div v-for="item in traits">
                            <span style="font-size: 15px">{{ item.description }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <my-separator v-if="product.material"/>

        <div v-if="product.material">
            <div class="container">
                <div class="row ptb--50"
                     data-aos="fade-up"
                     data-aos-delay="60">
                    <div class="offset-lg-1 col-lg-3 ptb-my--60" >
                        <span style="font-size: 25px;">产品材质</span>
                    </div>
                    <div class="col-lg-7 ptb-my--60" >
                        <p style="font-size: 15px">{{ product.material }}</p>
                    </div>
                </div>
            </div>
        </div>
        <my-separator v-if="product.otherTitle"/>

        <div v-if="product.otherTitle">
            <div class="container">
                <div class="row ptb--50"
                     data-aos="fade-up"
                     data-aos-delay="60">
                    <div class="offset-lg-1 col-lg-3 ptb-my--60" >
                        <span style="font-size: 25px;">{{ product.otherTitle }}</span>
                    </div>
                    <div class="col-lg-7 ptb-my--60" >
                        <p style="font-size: 15px">{{ product.otherDescription }}</p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<script>
import Layout from "@/components/common/Layout";
import Separator from "@/components/elements/separator/Separator";
import Video from "@/components/elements/video/Video.vue";
import SectionTitle from "@/components/elements/sectionTitle/SectionTitle";
import ServiceOne from "@/components/elements/service/ServiceOne";
import { product } from "@/api/home";
import MySeparator from "@/components/elements/separator/MySeparator.vue";

export default {
    name: 'ProductDetail',
    components: {MySeparator, ServiceOne, SectionTitle, Video, Separator, Layout},
    props: ['id'],
    data() {
        return {
            product: {},
            videoData: [],
            pictures: [],
            traits: [],
            textParams: [],
            serviceList: [
                {
                    icon: 'leaves',
                    description: '具有除尘装置，无粉尘飞扬，卫生环保'
                },
                {
                    icon: 'worker',
                    description: '人性化设计，符合人体工程学，投料效率高'
                },
                {
                    icon: 'graphic-design',
                    description: '独特网架设计，受力均匀，更换方便'
                },
                {
                    icon: 'selected-focus',
                    description: '过滤精度高，最大限度避免粉尘随排风逸出'
                },
                {
                    icon: 'system',
                    description: '模块化、集成化、智能化设计，拆装便捷，维修简单，操作方便'
                },
                {
                    icon: 'waterfalls-h',
                    description: '可根据客户要求非标定制，可定制防爆配置'
                },
            ],
            tableTitles: ['标题1','标题2','标题3','标题4','标题5'],
            tableData: [
                { val1: '文字内容', val2: '文字内容', val3: '文字内容', val4: '123213123123', val5: '123213123123'},
                { val1: '文字内容', val2: '文字内容', val3: '文字内容', val4: '123213123123', val5: '123213123123'},
                { val1: '文字内容', val2: '文字内容', val3: '文字内容', val4: '123213123123', val5: '123213123123'},
                { val1: '文字内容', val2: '文字内容', val3: '文字内容', val4: '123213123123', val5: '123213123123'},
                { val1: '文字内容', val2: '文字内容', val3: '文字内容', val4: '123213123123', val5: '123213123123'}
            ]
        }
    },
    created() {
        this.init(this.$route.params.id)
    },
    watch: {
        '$route.params.id'(newId) {
            this.init(newId);
        },
    },
    methods: {
        init(id) {
            product(id).then(res => {
                console.log(res.data.data)
                this.product = res.data.data.product
                this.product.coverUrl = process.env.VUE_APP_BASE_API + this.product.coverUrl
                if (res.data.data.video) {
                    this.videoData.push({ thumb: res.data.data.videoCover, src: res.data.data.video })
                }
                this.pictures = res.data.data.pictures
                this.pictures.forEach(item => {
                    item.url = process.env.VUE_APP_BASE_API + item.url
                })
                this.traits = res.data.data.traits
                this.textParams = res.data.data.textParams
                this.tableTitles = res.data.data.titles
                this.tableData = res.data.data.tableParams
            })
        }
    }

}

</script>
