<template>
    <Layout cheader-class="header-transparent" :footer-style="1" :show-cta="false">
        <div class="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow">
            <div class="height-636 bg-overlay bg_image" :style="{'background-image': `url(${require(`@/assets/images/introduction/banner.png`)})`}" style="height: 412px">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="product-banner" style="text-align: right;">
                                <h1>做优质产品</h1>
                                <h1>创国内一流品牌</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="mt--100 mb--50">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 d-flex flex-column justify-content-center"
                         data-aos="fade-up"
                         data-aos-delay="60">
                        <span>ABOUT US</span>
                        <h4>关于泷沙机械</h4>
                        <p>河北泷沙机械科技有限公司成立于2016年，位于中华北大街与北环城高速交口，其地理位置非常优越，交通便利。公司自成立以来，始终坚持以人为本、诚信立业的经营原则，使企业在激烈的市场竞争中终保持竞争力，实现企业快速、稳定地发展。公司经营范国包括食品机械及配件，包装机械及配件，制药设备及配件，非标自动化设备及配件，桁架机器人及配件，智能库设备及配件，铁路运输设备配件的生产、销售。河北泷沙机械科技有限公司以诚信、实力和产品质量获得业界的高度认可。欢迎各界朋友莅临参观、指导和业务洽谈。</p>
                    </div>
                    <div class="offset-lg-1 col-lg-5"
                         data-aos="fade-up"
                         data-aos-delay="60">
                        <img src="~@/assets/images/introduction/sp1.png" />
                    </div>
                </div>
            </div>
        </div>


        <div class="mt--50 mb--50">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12"
                         data-aos="fade-up"
                         data-aos-delay="60">
                        <span>CORPORATE CULTURE</span>
                        <h4>企业文化</h4>
                    </div>
                </div>
                <div class="row culture">
                    <div class="col-lg-1-5 pt--15" data-aos="fade-up" data-aos-delay="60">
                        <div class="d-flex flex-column align-items-center">
                            <Icon name="bookmark-three" size="50" class="mt--35" fill="#4a90e2"/>
                            <h5 class="mt--30">企业精神</h5>
                            <p>因队协作、敬业奉献、诚信为本、创新卓越</p>
                        </div>
                    </div>
                    <div class="col-lg-1-5 pt--15" data-aos="fade-up" data-aos-delay="60">
                        <div class="d-flex flex-column align-items-center">
                            <Icon name="concept-sharing" size="50" class="mt--35" fill="#f85a40"/>
                            <h5 class="mt--30">经营理念</h5>
                            <p>信誉第一，质量第一</p>
                        </div>
                    </div>
                    <div class="col-lg-1-5 pt--15" data-aos="fade-up" data-aos-delay="60">
                        <div class="d-flex flex-column align-items-center">
                            <Icon name="success" size="50" class="mt--35" fill="#00c16e"/>
                            <h5 class="mt--30">市场领先</h5>
                            <p>我们的目标就是靠创新思维和创新能力，创造我们在投资开发、产品开发的领先地位，以市场领先来支持我们不断增长的效益</p>
                        </div>
                    </div>
                    <div class="col-lg-1-5 pt--15" data-aos="fade-up" data-aos-delay="60">
                        <div class="d-flex flex-column align-items-center">
                            <Icon name="target" size="50" class="mt--35" fill="#7552cc"/>
                            <h5 class="mt--30">企业目标</h5>
                            <p>我们的发展目标是致力于在中国不锈钢加工行业创造实力型企业，坚持企业可持续的、协调的、全面的、健康的发展，创建行业的知名品牌企业</p>
                        </div>
                    </div>
                    <div class="col-lg-1-5 pt--15" data-aos="fade-up" data-aos-delay="100">
                        <div class="d-flex flex-column align-items-center">
                            <Icon name="peoples" size="50" class="mt--35" fill="#0cb9c1"/>
                            <h5 class="mt--30">企业员工</h5>
                            <p>忠诚•务实•团结•创新</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt--100 mb--100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6"
                         data-aos="fade-up"
                         data-aos-delay="60">
                        <img src="~@/assets/images/introduction/sp11.png" style="width: 100%;"/>
                    </div>
                    <div class="col-lg-6 d-flex flex-column justify-content-between"
                         data-aos="fade-up"
                         data-aos-delay="100">
                        <div class="row">
                            <div class="col-lg-12">
                                <img src="~@/assets/images/introduction/mb7.png" style="width: 100%"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <img src="~@/assets/images/introduction/mb.png" style="width: 100%"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<script>
import Layout from "@/components/common/Layout";
import Separator from "@/components/elements/separator/Separator";
import Icon from "@/components/icon/Icon";

export default {
    name: 'Introduction',
    components: {Icon, Separator, Layout}
}
</script>
