<template>
    <div v-if="videoStyle === 1">
        <div class="row row--15">
            <div :class="column"
                 v-for="(video, index) in videoData"
                 :key="index">
                <div class="video-popup icon-center" data-aos="fade-up">
                    <div class="thumbnail">
                        <img class="radius-small"
                             :src="video.thumb"
                             alt="Video Thumbnail"/>
                    </div>
                    <div class="video-icon">
                        <Button variant-button-class="rounded-player popup-video"
                                :is-link="false"
                                @btnClick="imageIndex = index">
                            <span><Icon name="play" size="30"/></span>
                        </Button>
                    </div>
                </div>
            </div>
            <div :class="column">
                <div class="container pt-5"
                     data-aos="fade-up"
                     data-aos-delay="60">
                    <P>{{description}}</P>
                </div>
            </div>
        </div>
        <CoolLightBox
            :items="videoData"
            :index="imageIndex"
            @close="imageIndex = null">
        </CoolLightBox>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'
    import Button from '../button/Button'

    export default {
        name: 'Video',
        components: {Button, Icon},
        props: {
            videoData: {},
            description: {
                type: String,
                require: true
            },
            videoStyle: {
                type: Number,
                default: 1
            },
            column: {
                type: String,
                default: 'col-lg-6 col-md-6 col-12 mt--30'
            }
        },
        data() {
            return {
                imageIndex: null
            }
        }
    }
</script>
