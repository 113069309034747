<template>
    <div class="rn-default-tab style-three">
        <ul class="nav nav-tabs tab-button" role="tablist">
            <li class="nav-item tabs__tab"
                role="presentation"
                v-for="(tabMenu, index) in tabData"
                :key="index">
                <button class="nav-link text-capitalize"
                        :class="{'active': index === activeTab}"
                        :id="`${tabMenu.id}-tab`"
                        data-bs-toggle="tab"
                        :data-bs-target="`#${tabMenu.id}`"
                        type="button"
                        role="tab"
                        :aria-controls="tabMenu.id"
                        :aria-selected="index === activeTab ? 'true' : 'false'"
                        @click="activeTab = index">
                    {{ tabMenu.text }}
                </button>
            </li>
        </ul>

        <div class="rn-tab-content tab-content">
            <div class="tab-pane fade"
                 :class="{'show active': index === activeTab}"
                 :id="content.id"
                 role="tabpanel"
                 :aria-labelledby="`${content.id}-tab`"
                 v-for="(content, index) in tabData"
                 :key="index">
                <div class="inner">
                    <div class="row align-items-center row--30">
                        <div class="col-lg-12 mt_md--30 mt_sm--30">
                            <div class="row row--15 mt_dec--30 service-wrapper">
                                <div :class="content.dataList.length === 2 ? 'col-lg-6 col-md-6 col-sm-6 col-12' : 'col-lg-4 col-md-6 col-sm-6 col-12'"
                                     v-for="(service, index) in content.dataList"
                                     :key="`service-${index}`">
                                    <div class="card-box card-style-1 text-left">
                                        <div class="inner">
                                            <div class="image">
                                                <router-link to="#">
                                                    <img :src="service.image" alt="Card Images">
                                                </router-link>
                                            </div>
                                            <div class="content">
                                                <h4 class="title mb--20">
                                                    <router-link to="#">
                                                        {{ service.title }}
                                                    </router-link>
                                                </h4>
                                                <p class="description b1 color-gray mb--0">
                                                    {{ service.description }}
                                                </p>
                                                <router-link class="btn-default btn-small" :to="{ name: 'ProductDetail', params: { id: service.id }}">
                                                    详情
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Tab',
        props: {
            tabData: {},
            active: {
                type: Number,
                default: 0
            }
        },
        watch: {
          active(newParams, oldParams) {
            this.activeTab = newParams
          }
        },
        data() {
            return {
                activeTab: 0
            }
        },
        created() {
            this.activeTab = this.active
        }
    }
</script>
