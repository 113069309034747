<template>
    <Layout>
        <Breadcrumb title="行业资讯"/>

        <div class="rn-blog-area rn-section-gap">
            <div class="container">
                <div class="row mt_dec--30">
                    <div class="col-lg-12">
                        <div class="row row--15">
                            <div class="col-lg-4 col-md-6 col-12 mt--30"
                                 data-aos="slide-up"
                                 data-aos-duration="800"
                                 :data-aos-delay="100 + index"
                                 v-for="(blog, index) in posts"
                                 :key="index">
                                <BlogPost :blog="blog"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 text-center">
                        <div class="rwt-load-more text-center mt--60">
                            <a class="btn-default" href="#" @click="init" v-if="!showEnd">
                                查看更多 <Icon name="loading-two"/>
                            </a>
                            <span v-else>没有更多了...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from '../../components/common/Layout'
import Breadcrumb from '../../components/elements/breadcrumb/Breadcrumb'
import BlogPost from '../../components/blog/BlogPost'
import Icon from '../../components/icon/Icon'
import { newsList } from "@/api/home"

export default {
    name: 'News',
    components: {Icon, BlogPost, Layout, Breadcrumb},
    data() {
        return {
            posts: [],
            showEnd: false
        }
    },
    created() {
        this.init()
    },
    methods: {
        init(event) {
            if (event) event.preventDefault()
            newsList(this.posts.length).then(res => {
                if (!res.data.data || res.data.data.length < 9) {
                    this.showEnd = true
                }
                if (res.data.data) {
                    res.data.data.forEach(item => {
                        this.posts.push({
                            id: item.id,
                            img: process.env.VUE_APP_BASE_API + item.coverUrl,
                            publishedAt: item.publishTime.substring(0,11),
                            title: item.title
                        })
                    })
                }
            })
        }
    }
}
</script>
